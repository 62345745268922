.headerCustom {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  grid-gap: 16px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 10px 16px;
  z-index: 999;
  text-align: end;
}

.settingsIcon {
  color: gray;
  margin-right: 20px;
}